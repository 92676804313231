module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../node_modules/@yumgmbh/gatsby-theme-yum-components/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@yumgmbh/gatsby-plugin-sb-nav/gatsby-browser.js'),
      options: {"plugins":[],"storyblokToken":"yx6aQccdzQn0Xu0PQwxYBwtt","version":"published","by_slugs":"global/navigations/*"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#0083bf","theme_color":"#0083bf","display":"minimal-ui","icon":"src/images/favicon.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"22c9ee1e0f20b799d34fb454059cd8ac"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
