import "./src/scss/style.scss"

export const onRouteUpdate = ({ location }) => {
  /* Since hassia-consent is not spa-able*/
  var spaconsent = document.head.querySelector("#hassia-consent-spa")
  if (spaconsent) {
    spaconsent.parentNode.removeChild(spaconsent)
  }

  var s = document.createElement("script")
  s.src = "/hassia-consent_itavero.de_20230814.js"
  s.id = "hassia-consent-spa"
  s.dataset.dialogDelay = 0
  s.dataset.mainColor = "#0083bf"
  s.dataset.accentColor = "#ffffff"
  s.defer = true

  document.head.append(s)
  return null
}
